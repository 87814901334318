<template>
  <div class="tabs-mobile-container">
    <div class="tabs-mobile">
      <div
        v-for="(tab, index) in selectedModel.slice(0, numberOfCars)"
        :key="index"
        class="tab"
      >
        <img :src="tab.image" :alt="tab.model" class="tab__image" />
        <h3 class="tab__title">{{ tab.brand }} {{ tab.model }}</h3>
        <p class="tab__vin">VIN: {{ tab.vin }}</p>
        <p class="tab__details">
          {{ tab.gearboxType }} &middot; {{ tab.driveType }} &middot;
          {{ tab.engine.type }} &middot; {{ tab.engine.volume }} &middot;
          {{ tab.engine.power }}
        </p>
        <p class="tab__color">Цвет: {{ tab.bodyType.color }}</p>
        <!-- <p class="tab__city">г. {{ tab.location }}</p> -->
        <p class="tab__city">г. Екатеринбург, Ул Щербакова 144</p>
        <hr />
        <p class="tab__old-price">{{ priceDecoration(tab.price.regular) }} ₽</p>
        <!-- <p v-if="tab.price.withDiscount" class="tab__new-price">
          {{ priceDecoration(tab.price.withDiscount) }} ₽
        </p>
        <p v-else class="tab__new-price">Цена по запросу</p> -->
        <p class="tab__credit">{{ getCredit(tab.model) }}</p>
        <button
          @click="toggleRequestForm($event, tab.model)"
          class="tab__btn btn-secondary"
        >
          Забронировать цену <br>  по акции до -20%
        </button>
      </div>
    </div>
    <button
      v-if="more"
      :style="{ disabled: !more }"
      @click="showMore"
      class="tabs-mobile__btn btn-primary"
    >
      Показать ещё
    </button>
  </div>
</template>

<script>
import siteData from "@/config.json";
export default {
  name: "TabComponent",
  props: ["selectedModel", "model"],
  data() {
    return {
      tabs: siteData.in_stock.tabs,
      numberOfCars: 6,
    };
  },
  watch: {
    model() {
      this.numberOfCars = 6;
    },
  },
  computed: {
    more() {
      return this.numberOfCars > Object.keys(this.selectedModel).length
        ? false
        : true;
    },
  },
  methods: {
    toggleRequestForm(event, model) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        car: model,
        form_position: "форма Автомобили в наличии",
      });
    },
    priceDecoration(price) {
      return new Intl.NumberFormat("ru", {
        maximumSignificantDigits: 3,
      }).format(price);
    },
    getCredit(model) {
      switch (model) {
        case "Tiggo 4 Pro":
          return "от 8 500 ₽ в месяц";
        case "Tiggo 7 Pro":
          return "от 7 500 ₽ в месяц";
        case "Tiggo 8":
          return "от 12 000 ₽ в месяц";
        case "Tiggo 8 Pro":
          return "от 16 000 ₽ в месяц";
        case "Tiggo 8 Pro Max New":
          return "от 16 900 ₽ в месяц";
        default:
          return "Кредит по запросу";
      }
    },
    showMore() {
      const carsCount = Object.keys(this.selectedModel).length;
      if (this.numberOfCars < carsCount) {
        this.numberOfCars += 6;
      }
    },
  },
};
</script>
